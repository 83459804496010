import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { TimelineLite, TweenMax, Power3 } from "gsap"

import {
  Box,
  FullScreenContainer,
  SectionTitle,
  SectionParagraph,
  ButtonRow,
} from "../../helpers/styledComponents"
import theme from "../../helpers/theme"

const AboutContent = () => {
  let app = useRef(null)
  let images = useRef(null)
  let content = useRef(null)
  let tl = new TimelineLite({ delay: 0.2 })

  useEffect(() => {
    const profileImage = images.firstElementChild
    const blackLine = content.firstElementChild.firstElementChild
    const sectionTitle = content.children[1]
    const sectionParagraph = content.children[2]
    const actionLink = content.children[3]

    // remove initial flash
    TweenMax.to(app, 0, { css: { visibility: "visible" } })

    // images animation
    tl.from(profileImage, 1.2, { y: 1280, ease: Power3.easeOut }, "start")
      .from(
        profileImage.firstElementChild,
        2,
        { scale: 1.6, ease: Power3.easeOut },
        0.2
      )
      .from(
        blackLine,
        1.5,
        { scale: 0, ease: Power3.easeOut, delay: "1" },
        "start"
      )

    // content animation
    tl.staggerFrom(
      [sectionTitle.children],
      1,
      {
        y: 44,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    )
      .from(
        sectionParagraph,
        1,
        { y: 20, opacity: 0, ease: Power3.easeOut },
        1.4
      )
      .from(actionLink, 1, { y: 20, opacity: 0, ease: Power3.easeOut }, 1.6)
  })

  const data = useStaticQuery(graphql`
    query {
      profileImageUrl: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Outer ref={el => (app = el)}>
      <FullScreenContainer>
        <Content>
          <ImagesContainer ref={el => (images = el)}>
            <ProfileImage>
              <Img fluid={data.profileImageUrl.childImageSharp.fluid} />
            </ProfileImage>
          </ImagesContainer>
          <TextWrapper>
            <TextContainer ref={el => (content = el)}>
              <LineOuter>
                <Line />
              </LineOuter>
              <Box
                m={0}
                height={["36px", "44px"]}
                css={{
                  overflow: "hidden",
                }}
              >
                <SectionTitle as="h3">About Jeremy</SectionTitle>
              </Box>
              <Box>
                <SectionParagraph as="p">
                  Born and raised in Hong Kong, Jeremy is a photographer who
                  focuses on caputuring images of wildlife. Since he was a young
                  child, he has always been fascinated by all critters and
                  creatures. Combining his love for photography and passion for
                  nature, he aims to tell stories through photographs of the
                  colorful and miraculous natural world.
                </SectionParagraph>
              </Box>
              <ButtonRow>
                <Link to="/work">View Work ></Link>
              </ButtonRow>
            </TextContainer>
          </TextWrapper>
        </Content>
      </FullScreenContainer>
    </Outer>
  )
}

const Outer = styled(Box)({
  visibility: "hidden",
  padding: `${theme.spaces[2]}px 0`,
  position: "relative",
  background: theme.colors.white,
  [theme.mediaQueries[3]]: {
    padding: `${theme.spaces[4]}px 0`,
    minHeight: "80vh",
  },
})

const Content = styled(Box)({
  display: "flex",
  flexDirection: "column",
  [theme.mediaQueries[3]]: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: `0 ${theme.spaces[5]}px`,
  },
})

const TextWrapper = styled(Box)({
  paddingTop: `${theme.spaces[5]}px`,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  order: 2,
  [theme.mediaQueries[3]]: {
    paddingTop: `170px`,
    width: "40%",
  },
})

const TextContainer = styled(Box)({
  maxWidth: "180px",
  margin: `${theme.spaces[4]}px 0`,
  paddingRight: `${theme.spaces[2]}px`,
  [theme.mediaQueries[3]]: {
    margin: "unset",
    maxWidth: "unset",
    paddingRight: `${theme.spaces[5]}px`,
  },
})

const LineOuter = styled(Box)({
  zIndex: 13,
  position: "relative",
  left: "-80%",
  paddingBottom: "28px",
  [theme.mediaQueries[3]]: {
    left: "-40%",
  },
})

const Line = styled(Box)({
  width: "130px",
  height: "1px",
  background: theme.colors.black,
  [theme.mediaQueries[2]]: {
    width: "150px",
  },
  [theme.mediaQueries[3]]: {
    width: "180px",
  },
})

const ImagesContainer = styled(Box)({
  order: 1,
  position: "relative",
  zIndex: 7,
  overflow: "hidden",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  [theme.mediaQueries[3]]: {
    paddingRight: `${theme.spaces[5]}px`,
    justifyContent: "flex-end",
    order: 1,
    height: "650px",
    width: "50%",
  },
})

const ProfileImage = styled(Box)({
  zIndex: 4,
  width: "100%",
  position: "relative",
  [theme.mediaQueries[3]]: {
    width: "80%",
  },
})

export default AboutContent
