import React from "react"
import AboutContent from "../components/aboutPage/aboutContent"
import BaseLayout from "../components/layout/base"
import SEO from "../components/utils/seo"

const AboutPage = props => {
  return (
    <BaseLayout>
      <SEO title="About" />
      <AboutContent />
    </BaseLayout>
  )
}

export default AboutPage
